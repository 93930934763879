
// global variables
*{
    text-rendering: optimizeLegibility !important;
}
@import 
    "global/var" 
,   "global/var-extra"
,   "material-design-lite/variables" 
,   "theme/var-theme"
;

@import
    "libs/angular-material"
,   "libs/angular-material-data-table"
,   "libs/angular-wizard"
,   "libs/text-angular"
,   "libs/fullcalendar"
;


@import
// Pages
    "page/demo"
,   "page/auth"
,   "page/error"
,   "page/lock-screen"
,   "page/profile"
,   "page/invoice"
,   "page/dashboard"
,   "page/maintenance"
,   "page/about"
,   "page/terms"
,   "page/blog"
,   "page/with-tabs"
,   "page/alarms"
;

@import
    "app/tasks"
,   "app/email"
;