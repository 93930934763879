$zindex_dropdown_menu:      ($zindex-dropdown + 1); // larger than zindex of header, sidebar, quickview


// Z-index
// Menu, Select
.md-select-menu-container, 
.md-open-menu-container {
    z-index: $zindex_dropdown_menu;
}
// Dialog
md-backdrop.md-dialog-backdrop {
    z-index: $zindex-modal-background;
}
.md-dialog-container {
    z-index: ($zindex-modal-background + 1);
}
md-toast {
    z-index: $zindex-tooltip !important;
}

// Menu
md-menu-item {
    > a {
        &:hover,
        &:focus {
            color: $text-color;
        }
    }
}


// Radio
.ui-md-radio-group-inline {
    md-radio-button {
        display: inline-block;
        margin: 0 10px 10px;
    }
}
md-radio-button{
    &[disabled] ._md-on {
        background-color: rgba(#000, .25);
    }
}

// Toast
md-toast {
    position: fixed !important;
}

// Select
.md-select-header .header-searchbox {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0; }
.md-select-header .select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 10.667px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: auto; }
.md-select-header md-content._md {
    max-height: 240px; }



// Custom style
// --------------------------------------------------

// md-list, for top header
.box-body > md-list {
    padding: 0;
}
.md-subheader.md-default-theme,
.md-subheader {
    // background color can't be alpha because of Material subheader
}
.ui-md-list {
    md-list-item {
        ._md-list-item-inner>md-icon:first-child:not(.md-avatar-icon) {
            margin: 5px 25px 5px 0;
        }
        &.md-2-line {
            min-height: 60px;
            > ._md-no-style{
                min-height: 60px;
                height: 60px;
            }
            .md-list-item-text {
                h3 {
                    font-size: $font-size-base;
                }
                p {
                    font-size: $font-size-small;
                }
            }
        }
    }

}



// Theme
// --------------------------------------------------

// Autocomplete
.theme-gray,
.theme-dark {
    md-autocomplete.md-default-theme,
    md-autocomplete {
        background-color: rgba(#fff, .1)
    }
}


// cards
.theme-gray,
.theme-dark {
    md-card {
        color: $theme_dark_text_color;
    }
}
.theme-gray {
    md-card {
        background-color: $theme_gray_box_bg;
    }
    .md-subheader.md-default-theme,
    .md-subheader {
        // background color can't be alpha because of Material subheader
        background-color: lighten($theme_gray_box_bg, 3%);
    }
}
.theme-dark {
    md-card {
        background-color: $theme_dark_box_bg;
    }
    .md-subheader.md-default-theme,
    .md-subheader {
        // background color can't be alpha because of Material subheader
        background-color: lighten($theme_dark_box_bg, 3%);
    }
}


// Chips
.theme-gray,
.theme-dark {
    md-chips.md-default-theme md-chip,
    md-chips md-chip {
        background-color: rgba(#000, .1);
        color: $theme_dark_text_color;
    }
}

// Content
.theme-gray,
.theme-dark {
    md-content.md-default-theme,
    md-content {
        color: $theme_dark_text_color;
    }
}

.theme-dark {
    md-content.md-default-theme,
    md-content {
        background-color: $theme_dark_box_bg;
    }
}
.theme-gray {
    md-content.md-default-theme,
    md-content {
        background-color: $theme_gray_box_bg;
    }
}

// Tab

.theme-dark {
    md-tabs {
        background-color: $theme_dark_box_bg;
        border-color: red;
    }
}
.theme-gray {
    md-tabs {
        background-color: $theme_gray_box_bg;
        border-color: red;
    }
}


// Toast
md-toast {
    &.md-toast-primary {
        > .md-toast-content {
            background-color: $brand-primary;
        }
    }
    &.md-toast-info {
        > .md-toast-content {
            background-color: $brand-info;
        }
    }
    &.md-toast-success {
        > .md-toast-content {
            background-color: $brand-success;
        }
    }
    &.md-toast-danger {
        > .md-toast-content {
            background-color: $brand-danger;
        }
    }
}
